@import "../../color";

.wrapper {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #aca0a0;

}

.verifyEmailCard {
  width: 500px;
  height: 50%;
  border-radius: 0.5rem;
  background-color: $white;
  padding: 1rem;
}

.title {
  color: $primary;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 5rem;
}