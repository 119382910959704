@import url("./fonts.scss");

*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: "Inter";
  font-weight: normal;
  font-size: 16px;
  margin: 0;
}

:root {
  font-size: 16px;
}

body {
  overflow-x: hidden;
  font-size: 16px;
  padding: 0;
}

strong {
  font-weight: bold;
}
