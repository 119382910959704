@import "../../../color.scss";

.analysisBox {
  padding: 2rem;
  background-color: $lighter-gray;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  &.error {
    background-color: $lavendar-blush;
  }

  &.solution {
    background-color: $green;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .question {
    color: $dark-primary;

    span {
      color: $gray;
      margin-left: 1rem;
      cursor: pointer;
      transition: color 0.15s ease-in;

      &:hover {
        color: $primary;
      }
    }
  }
}
