@import "../../color";

.grecaptcha-badge {
  display: none ;
}

.containerWrapper {
  display: flex;
  width: 100%;
  padding: 1rem;
  margin-top: 100px;
  margin-left: 200px;
}

.container {
  width: 100%;
  max-width: 1000px;
}

.searchbar {
  display: flex;
  justify-content: space-between;
  margin-left: 200px;
  margin-bottom: 80px;
  max-width: 2000px;
}
.searchbar input[type="text"] {
  width: 1100px;
  border-color: $light-gray;
  border-radius: 10px;
  margin-right: 150px;
  margin-left: -260px;
}
.searchbar button {
  margin-right: -560px;
}

.buttonCreateFirst {
  margin-top: 1rem;
  height: 40px;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reportTable {
  margin-top: 40px;
  width: 150%;
  border-collapse: collapse;
  margin-top: 1rem;

  td {
    border: none;
    padding: 14px;
    text-align: center;
    font-size: 17px;
  }

  th {
    background-color: $white;
    color: $dark-primary;
    font-weight: bold;
    font-size: 19px;
    padding: 18px;
  }

  tr {
    border-bottom: 1px solid $gray;
  }

  tr:nth-child(even) {
    background-color: $white;
  }

  tr:hover {
    background-color: $dark-primary;
    color: $white;
  }

  td:last-child {
    border-right: 1px solid $gray;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
  }
  td:first-child {
    text-align: center;
    border-left: 1px solid $gray;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
  }
}
.buttoncheck {
  color: transparent;
}
.subscriptionStatus {
  &.free {
    color: $green-dark;
    background-color: $green-light;
    padding: 8px;
    border-radius: 3px;
    font-size: 17px;
  }

  &.paid {
    color: $dark-blue;
    background-color: $blue-light;
    padding: 8px;
    border-radius: 3px;
    font-size: 17px;
  }
}
