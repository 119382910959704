@import "../../../color.scss";

.specialityButtonContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
  
.specialityButtonGroup {
    display: flex;
  
    justify-content: space-between;
    margin-bottom: 10px;
}
.selectedSpeciality {
    background-color: $dark-primary;
    color: white;
}
.selectedSpeciality:hover {
    background-color: $light-primary;
    color: white;
}
.space {
    margin-top: 20px;
}