@import "../../../color.scss";

.noteContainer {
  background-color: $dark-primary;
  border-radius: 10px;
  color: $white;
  line-height: 21px;
  align-items: center;
  padding: 10px;
  display: flex;
}

.title {
  font-size: 14px;
  color: $white;
  margin-right: 1px;
  white-space: nowrap;
}

.paragraph {
  font-size: 14px;
  flex: 1;
  white-space: nowrap;
}
