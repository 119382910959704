@import "../../color.scss";

.grecaptcha-badge {
  display: none ;
}

.settingsPage {
  padding: 100px 70px;
}

.settingsSectionLayout {
  display: flex;
  gap: 50px;
  margin-bottom: 20px;

  > div {
    max-width: 50%;
  }
}

.textGroup {
  flex: 1;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
}

.textGroupItem {
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.textGroupItemCompany {
  margin-top: 170px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.textGroupItemLogin {
  margin-bottom: 220px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

h3 {
  color: black;
  font-weight: bold;
  size: 24px;
}

p {
  font-weight: 300;
  size: 24px;
  line-height: 36px;
  letter-spacing: -1.1;
}

.formContainer {
  flex: 2;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.formGroupContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formGroup {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.inputGroup {
  flex: 1 1 45%;
}

.fullWidth {
  width: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.passwordGroup {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label {
  display: block;
  margin-bottom: 5px;
}

.error {
  color: red;
  font-size: 12px;
}

.deleteAccount {
  display: flex;
  align-items: center; /* Aligner verticalement au centre */
  justify-content: space-between; /* Crée de l'espace entre le titre et le bouton */
  margin: 20px 0; /* Ajuster l'espacement en bas si nécessaire */
  line-height: 36px;
  font-size: 24px;
  font-weight: 500;
}

.deleteButton {
  font-weight: bold;
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
}

.deleteAccountText {
  color: rgb(159, 155, 155);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 100px;
}

.roleOptions {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.roleOption {
  border-color: $light-gray;

  &.selected {
    background-color: $dark-primary;
    color: $white;
    border-color: $dark-primary;
  }
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin-top: 3px;
}
.successMessage {
  color: green;
  font-size: 14px;
  margin-top: 3px;
}
.inputGroupItem,
.inputGroupItemCompany,
.inputGroupItemLogin {
  width: 30%;
  display: flex;
  flex-direction: column;
}
