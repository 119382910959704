@import "../../../color.scss";

.reportCardsGroup {
  background-color: $lighter-gray;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.375rem 2rem;

  .title {
    font-size: 1.5em;
    font-family: "Inter";
    color: $text-dark;
  }

  .description {
    color: $text-light;
    font-size: 0.875rem;
  }

  .cardsWrapper {
    display: flex;
    gap: 1rem;
  }
}
