@import "../../color";

.containerWrapper {
  display: flex;
  width: 100%;
  padding: 1rem;
  margin-top: 100px;
  margin-left: 200px;
}

.container {
  width: 100%;
  max-width: 1000px;
}

.searchbar {
  display: flex;
  justify-content: space-between;
  margin-left: 200px;
  margin-bottom: 80px;
  max-width: 2000px;
}
.searchbar input[type="text"] {
  width: 1100px;
  border-color: $light-gray;
  border-radius: 10px;
  margin-right: 150px;
  margin-left: -260px;
}
.searchbar button {
  margin-right: -560px;
}

.buttonCreateFirst {
  margin-top: 1rem;
  height: 40px;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reportTable {
  margin-top: 40px;
  width: 150%;
  border-collapse: collapse;
  margin-top: 1rem;

  td {
    border: none;
    padding: 14px;
    text-align: center;
    font-size: 15px;
  }

  th {
    background-color: $white;
    color: $dark-primary;
    font-weight: bold;
    font-size: 17px;
    padding: 18px;
  }

  tr {
    border-bottom: 1px solid $gray;
  }

  tr:nth-child(even) {
    background-color: $white;
  }

  tr:hover {
    background-color: $light-gray;
  }

  td:last-child {
    border-right: 1px solid $gray;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
  }
  td:first-child {
    text-align: center;
    border-left: 1px solid $gray;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
  }
}
.buttoncheck {
  color: transparent;
}

.viewLink {
  color: $black;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in;

  &:hover {
    color: $white;
    background-color: $primary;
  }
}

.alertMessage {
  position: fixed; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $light-gray;
  color: $primary; 
  padding: 20px 40px;
  border-radius: 8px;
  font-size: 18px; 
  text-align: center;
  z-index: 1000; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.alertMessage button {
  background: none;
  border: none;
  color: var(--primary-color); 
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.grecaptcha-badge {
  display: none ;
}