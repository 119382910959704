.grecaptcha-badge {
  display: none;
}

@media screen and (max-width: 768px) {
  body,
  html {
    overflow-x: hidden;
    max-width: 100vw;
  }
}
