@import "../../../color.scss";

.scoreCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  color: black;
  border: 1px solid $light-gray;
  border-radius: 2rem;
  margin-bottom: 1rem;
  background-color: $white;
  text-align: center;

  .smallTitle {
    font-size: 0.75rem;
  }

  .status {
    padding: 6px 12px;
    border-radius: 1rem;
    min-width: 84px;
    font-size: 0.875rem;
    text-align: center;
  }

  .value {
    font-weight: 600;
  }

  .topSection {
    display: flex;

    &:not(.styleTwo) {
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    &.styleTwo {
      justify-content: space-between;
      width: 100%;
    }
  }

  .bottomSection {
    display: flex;

    &:not(.styleTwo) {
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }

    &.styleTwo {
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  &.disbaled {
    background-color: $lighter-gray;

    .status {
      background-color: $light-gray;
    }
  }

  &.bad {
    .status {
      background-color: $lavendar-blush;
      color: $rose;
    }
  }

  &.medium {
    .status {
      background-color: $lemon;
      color: $orange;
    }
  }

  &.good {
    .status {
      background-color: $green;
      color: $dark-green;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}
