@import "../../../color.scss";

.spinner {
  text-align: center;
  margin-top: 80px;
  margin-left: 380px;
  width: 220px;
  height: 220px;
  display: grid;
  -webkit-mask: radial-gradient(farthest-side, #0000 40%, #1a1dc9 41%);
  mask: radial-gradient(farthest-side, #0000 40%, #1a1dc9 41%);
  background: linear-gradient(
        0deg,
        rgba(71, 75, 255, 0.5) 50%,
        rgba(71, 75, 255, 1) 0
      )
      center/20px 80% border-box,
    linear-gradient(
        90deg,
        rgba(71, 75, 255, 0.25) 50%,
        rgba(71, 75, 255, 0.75) 0
      )
      center/80% 20px border-box;

  background-repeat: no-repeat;
  animation: spinner-d3o0rx 3s infinite steps(12);
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}

.spinner::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes spinner-d3o0rx {
  100% {
    transform: rotate(1turn);
  }
}

.timer {
  text-align: center;
  margin-top: 20px;
  justify-content: center;
  font-size: 20px;
  color: $dark-primary;
  font-family: "krona one";
}

.notifSentence {
  text-align: center;
  margin-top: 20px;
  justify-content: center;
  font-size: 18px;
  color: $black;
  font-family: "Arial";
}
