@import "../../../color";

.title {
  font-weight: 600;
  color: $text-light;
}

.value {
  font-size: 4rem;
  font-weight: 800;
  color: $text-dark;
}
