.collapsable {
  width: 100%;
  margin: 1rem 0;

  .titleSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
