@import "../../../color.scss";

.input {
  border-radius: 0.25rem;
  border: 1px solid $gray;
  padding: 0.85rem;
  transition: border 0.15s ease-in;

  &.block {
    width: 100%;
  }

  &.error {
    border-color: $red;
  }

  &:hover {
    border-color: $light-primary;
  }

  &:focus {
    outline: none;
    border-color: $primary;
  }
}

.inputContainer {
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%) scale(1.5);
  cursor: pointer;
}
