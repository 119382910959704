@import "../../../color.scss";

.fontPreview {
  display: block;
  width: 100%;
  position: relative;
  color: black;
  margin-bottom: 0.5rem;
  border: 1px solid $light-gray;
  border-radius: 0.25rem;
  overflow: hidden;
  transition: all 0.45s ease-in;

  &:hover {
    border-color: $light-primary;
    .header {
      .fontName {
        color: $light-primary;
      }

      .arrowIcon {
        fill: $light-primary;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
    cursor: pointer;
    background-color: $white;
    border: none;
    text-align: left;

    .fontName {
      font-size: 2rem;
      text-transform: capitalize;
      transition: color 0.15s ease-in;
    }

    .arrowIcon {
      height: 24px;
      width: 24px;
      transition: all 0.15s ease-in;

      &.rotate {
        transform: rotate(-180deg);
      }
    }
  }

  .body {
    padding: 0;
    max-height: 0;
    overflow: hidden;

    .preview {
      width: 100%;
      height: auto;
    }

    &.open {
      padding: 0 1rem 1rem 1rem;
      max-height: 99999px;
    }
  }
}
