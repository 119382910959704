$lighter-gray: #f6f6f6;
$light-gray: #e3e0e7;
$gray: #6b6d76;
$light-primary: #6d72c3;
$primary: #5941a9;
$black: #100b1d;
$text-dark: #202020;
$text-light: #84818a;
$white: white;
$light-red: #ec3325;
$red: #e3170a;
$dark-red: #a82002;
$rose: #f75151;
$melon: #fcbfb7;
$lavendar-blush: #fbeeee;
$lemon: #fcf7e1;
$yellow: #f5c000;
$orange: #dd7700;
$green: #e7f4f2;
$dark-green: #2ba795;
$light-blue: #87bfff;
$dark-blue: #3b28cc;
$dark-primary: #0f0679;
$night-blue: #040a45;
$green-light: #abd6ae;
$green-dark: #09540e;
$blue-light: #a8d0ff;
$green-pass: #308325;
