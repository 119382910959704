@import "../../../color";

.colorsDetails {
  display: block;

  .title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .colorCard {
    padding: 0.5rem;
    border: 1px solid $light-gray;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;

    .colorPreview {
      padding: 1rem;
      padding-bottom: 3rem;
      border-radius: 0.25rem;
      border: 1px solid $light-gray;

      .code {
        font-size: 3rem;
      }
    }

    .pairingsTitle {
      margin: 1rem 0;
    }

    .pairings {
      display: flex;
      flex-wrap: wrap;

      .pair {
        padding: 0.5rem;
        min-width: 100px;
        margin-right: 1rem;
        border-radius: 0.5rem;
        border: 1px solid $light-gray;
        text-align: center;

        .score {
          font-size: 2rem;
          font-weight: 700;
        }
      }
    }
  }
}
