@import "../../../color.scss";
.paragraphContainer {
  margin-bottom: 20px;
}

.title {
  font-size: 1.2em;
  font-family: "Inter";
  color: $dark-primary;
  margin-bottom: 10px;
}

.paragraph {
  font-size: 1em;
}
