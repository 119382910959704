@import "../../../color.scss";
@import url("https://fonts.googleapis.com/css2?family=Krona+One&display=swap");

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;

  &.closed {
    opacity: 0;
    pointer-events: none;
  }

  &.open {
    opacity: 1;
    pointer-events: auto;
  }

  .content {
    background-color: $white;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    width: 750px;
    height: 600px;
    border-radius: 30px;
    border: 2px solid $light-gray;
  }

  .visosphere {
    text-align: left;
    margin-top: 20px;
    color: $dark-primary;
    font-family: "krona one";
    font-size: 18px;
  }

  .sentence {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    color: $black;
    font-family: "krona one";
  }

  .body {
    text-align: left;
  }

  .closeButton {
    margin-top: 30px;
    position: relative;
    float: right;
  }
  .startButton {
    margin-top: 30px;
    float: right;
    background-color: $dark-primary;
    color: $white;
    margin-left: 10px;
  }

  .inputGroup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;

    .inputContainer {
      flex: 1;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .roleSelection {
    margin-top: 60px;
    .roleOptions {
      display: flex;
      flex-wrap: wrap;

      .roleOption {
        flex: 0 0 calc(65% - 5px);
        margin-bottom: 8px;
      }
    }
  }
  .roleHeader {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
