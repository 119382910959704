@import "../../../color.scss";

.colorsTable {
  table-layout: fixed;
  width: 100%;
  border: 1px solid $light-gray;
  border-radius: 1rem;
  text-align: center;

  thead {
    width: 100%;
    th {
      padding: 1rem 2rem;
    }
  }

  tr {
    width: 100%;
    td {
      padding: 1rem 0.5rem;
    }
  }

  .colorPreview {
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    border: 1px solid $light-gray;
    border-radius: 10px;
    display: inline-block;
  }

  .colorInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .contrastPass {
    color: $green-pass;
  }

  .contrastFail {
    color: $red;
  }
}
