@import "../../color";
@import url("https://fonts.googleapis.com/css2?family=Krona+One&display=swap");
.grecaptcha-badge {
  display: none ;
}
.containerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $night-blue;
}

.sentence {
  font-family: "Arial";
  color: $white;
  text-align: center;
  font-size: 3.4rem;
  // margin-bottom: 37.8rem;
  margin-top: 11rem;
  font-weight: bold;
}

.imageContainer {
  margin-top: 1rem;
  margin-bottom: 3rem;

  img {
    width: 100%;
    height: auto;
  }
}

.buttonRow {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  button {
    margin-right: 1rem;
    border-radius: 1rem;
    color: $night-blue;
  }

  button:last-child {
    margin-right: 0;
    background-color: $gray;
    color: $white;
  }
}
