@import "../../../color.scss";

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 20px;
}

.reportCreated {
  width: 300px;
  padding: 15px;
  //text-align: 100px;
  background-color: $light-gray;
  border-radius: 5px;
  font-size: 18px;
}
.buttonGroup {
  display: flex;
  align-items: center;
}
.buttonSpacer {
  width: 1rem;
}
.note {
  background-color: $dark-primary;
  border-radius: 10px;
  color: $white;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  padding: 10px;
}
.boxsignup {
  box-shadow: 4px 4px 4px 0px #040A454A ;
  padding : 20px;
  margin-top : 20px;
  background-color: $light-gray ;
  justify-content: center;
  gap: 8px; 
  display: flex;

  .link {
    color: $primary;
    cursor: pointer;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
      &:hover {
        color: $gray;
        cursor: pointer;
      }
  
  }
  .p {
    margin-top: -7px;
    font-size: 18px;
    font-weight: 500;
  }


}
