@import "../../color";
@import url("https://fonts.googleapis.com/css2?family=Krona+One&display=swap");
.wrapper {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

.signupCard {
  width: 1080px;
  height: 900px;
  background-color: white;
  padding: 1rem;
  margin: 5.5rem;
}

.grayCard {
  width: 610px;
  height: 860px;
  border-radius: 1.5rem;
  margin-top: 2.8rem;
  margin-bottom: 2rem;
}

.roundedImage {
  border-radius: 15px; 
  border: 1px solid #ccc; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
  color: $dark-primary;
  text-align: left;
  font-size: 2.8rem;
  margin-bottom: 1.2rem;
  font-family: "Tahoma";
  font-weight: bold;
}

.visosphere {
  font-family: "krona one";
  color: $dark-primary;
  text-align: left;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  font-weight: bold;
  margin-top: 2rem;
}
.sentence {
  font-family: "krona one";
  color: $gray;
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 2.5rem;
  margin-top: 1rem;
}

.signInLink {
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  font-family: "krona one";
  span {
    cursor: pointer;
    color: $dark-primary;
    font-weight: bold;

    &:hover {
      color: #ddd;
    }
  }
}

.orDivider {
  text-align: center;
  margin-bottom: 1.2rem;
  margin-top: 1.2rem;
}

.dividerContainer {
  display: flex;
  align-items: center;
}

.dividerLine {
  flex-grow: 1;
  border: 0.1px solid $light-gray;
  margin: 0 10px;
}

.orText {
  background-color: $white;
  padding: 20px;
}

.containerWrapper {
  display: flex;
  justify-content: center;
  width: 1060px;
}

.inputContainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}
.inContainer {
  width: 1045px;
}

.checkboxLabel {
  margin-top: 30px;

  a {
    color: $dark-blue;
    text-decoration: none;
    font-weight: bold;
  }
}
.checkbox {
  transform: scale(1.5);
  margin-right: 20px;
  margin-left: 5px;
  margin-bottom: 30px;
}

.backContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 858px;
}


.backButton {
  text-decoration: none;
  color: $dark-primary;
  font-size: 25px;
  padding: 10px;
  cursor: pointer;
}

.backButton:hover {
  text-decoration: none;
}
.serverError {
  padding: 1rem;
  border: 1px solid $red;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  background-color: lighten($color: $red, $amount: 50%);
  color: $red;
}
