@import "../../color";

.grecaptcha-badge {
  display: none ;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-family: "Poppins";
  color: $night-blue;
  text-align: center;
  font-size: 3.2rem;
  margin-top: 1rem;
  font-weight: 700;
  line-height: 5.6rem;
}

.description {
  font-family: "Poppins";
  color: $gray;
  text-align: center;
  font-size: 1.75rem;
  margin-bottom: 2rem;
  font-weight: 400;
  line-height: 2.6rem;
}

.tableContainer,
.secondTableContainer {
  margin-top: 20px;
}

.table,
.secondTable {
  border-collapse: separate;
  width: 100%;
}

.table th,
.table td {
  border: 1px solid $white;
  padding: 25px;
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;

}


.table th,
.secondTable th {
  background-color: $white;
}

.table td,
.secondTable td {
  background-color: $white;
}

.table tr:first-child th:nth-child(1),
.secondTable tr:first-child th:nth-child(1) {
  font-weight: normal;
  font-size: 1.1rem;
  color: $gray;
}
/*
.table th:nth-child(2), .table td:nth-child(2) {
  border-left: 1px solid $gray;
}*/

.table th:nth-child(1),
.secondTable th:nth-child(1),
.table td:nth-child(1),
.secondTable td:nth-child(1) {
  width: 30%;
}

.table th:nth-child(2),
.secondTable th:nth-child(2),
.table td:nth-child(2),
.secondTable td:nth-child(2) {
  width: 20%;
}

.table th:nth-child(3),
.secondTable th:nth-child(3),
.table td:nth-child(3),
.secondTable td:nth-child(3) {
  width: 30%;
}

.table th:nth-child(4),
.secondTable th:nth-child(4),
.table td:nth-child(4),
.secondTable td:nth-child(4) {
  width: 30%;
}

.secondTable tr:last-child th,
.secondTable tr:last-child td {
  border-bottom: 1px solid $gray;
}

.button {
  background-color: $light-gray;
  color: $gray;
  border: none;
  border-radius: 16px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: normal;
  cursor: pointer;
  width: 180px;
}

.couponContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
  width: fit-content;
}

.couponTitle {
  font-size: 1rem;
  color: $night-blue;
  margin: 0;
}

.couponInput {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid $gray;
  border-radius: 4px;
  margin: 0 10px;
}

.checkoutButton {
  background-color: $night-blue;
  color: $white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: $light-primary;
  }
}
