@import "../../color";
@import url("https://fonts.googleapis.com/css2?family=Krona+One&display=swap");
.wrapper {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

.resetPasswordCard {
  width: 1050px;
  height: 900px;
  background-color: white;
  padding: 2rem;
  margin: 6.5rem;
}

.grayCard {
  width: 500px;
  background-color: $light-gray;
  height: 860px;
  border-radius: 1.5rem;
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.visosphere {
  font-family: "krona one";
  color: $dark-primary;
  text-align: left;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  font-weight: bold;
  margin-top: 0.8rem;
}

.title {
  color: $dark-primary;
  text-align: left;
  font-size: 2.8rem;
  margin-top: 12rem;
  margin-bottom: 2rem;
  font-family: "Tahoma";
  //font-weight: bold;
}

.serverError {
  padding: 1rem;
  border: 1px solid $red;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  background-color: lighten($color: $red, $amount: 50%);
  color: $red;
}

.signInLink {
  margin-top: 1rem;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  font-family: "Arial";
  span {
    cursor: pointer;
    color: $dark-primary;

    &:hover {
      color: #ddd;
    }
  }
}

.sentence {
  font-family: "krona one";
  color: $gray;
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 2.5rem;
  margin-top: 2rem;
}
