@import "../../../color";

.tabs {
  width: 100%;
}

.tabList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.tabItem {
  margin-top: 5px;
  position: relative;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
}

.tabItem::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.tabItem.active::after {
  background-color: $dark-primary;
}

.tabItem.active {
  color: $black;
}

.tabContent {
  margin-top: 20px;
}

.tabPane {
  display: none;
}

.tabPane.active {
  display: block;
}
