@import "../../color";
@import url("https://fonts.googleapis.com/css2?family=Krona+One&display=swap");
.sentence {
  font-family: "krona one";
  color: $dark-primary;
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 6rem;
  margin-top: 8rem;
  font-weight: bold;
}

.container {
  width: 100%;
  max-width: 1000px;
}

.visosphere {
  font-family: "krona one";
  color: $dark-primary;
  text-align: left;
  font-size: 1.3rem;
  font-weight: bold;
}

.SignupButton {
  position: right;
}

.containerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerdiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1700px;
  margin-top: 20px;
}

.searchbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
}

.searchbar input[type="text"] {
  width: 95%;
  border-color: $light-gray;
  border-radius: 10px;
  margin-right: 10px;
}

.searchbar button {
  width: 140px;
  border-radius: 6px;
}
.stopButtonContainer {
  margin-top: 100px;
  display: flex;
  margin-left: 360px;
}
