@import "../../../color";

.card {
  padding: 1rem 2rem;
  border: 1px solid $light-gray;
  border-radius: 0.5rem;
  background-color: $white;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 150px;
}
