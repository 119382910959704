@import "../../color";

.statistics {
  display: flex;
  flex-wrap: wrap;
  padding: 4rem;
  gap: 1rem;
}
.grecaptcha-badge {
  display: none ;
}

.worldMapContainer {
  width: 100%;
  height: 500px; /* Adjust based on your design */
  margin-top: 2rem;
}

.worldMapContainer h2 {
  text-align: center;
}
