@import "../../color";
@import url("https://fonts.googleapis.com/css2?family=Krona+One&display=swap");
.grecaptcha-badge {
  display: none ;
}
.containerWrapper {
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: center;
}
.sentence {
  font-family: "krona one";
  color: $dark-primary;
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 6rem;
  margin-top: 8rem;
  font-weight: bold;
}

.container {
  width: 100%;
  max-width: 1000px;
}

.stopcontainer {
  display: flex;
 justify-content: center;
}

.stopcontainer button {
  width: 140px;
  border-radius: 6px;
  margin-top: 160px;
}

.iconContainer {
  margin-top: 100px;
  display: flex;
  align-items: center;
  margin-right: 300px;
  gap: 60px;
}

.iconlink {
  margin-right: 10px;
  width: 270px;
  height: 70px;
  transform: rotate(45deg);
}
.icon {
  margin-right: 10px;
  width: 270px;
  height: 70px;
}

.iconWithText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  margin-top: 5px;
  font-size: 14px;
  // font-weight: bold;
}
.number {
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
}
.stopButtonContainer {
  margin-top: 100px;
  display: flex;
  margin-left: 360px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 20px;
}

.reportCreated {
  width: 260px;
  padding: 15px;
  //text-align: 100px;
  background-color: $light-gray;
  border-radius: 5px;
  font-size: 18px;
}
.buttonGroup {
  display: flex;
  align-items: center;
}

.buttonSpacer {
  width: 1rem;
}
