@import "../../../color";

.screenshots {
  position: relative;

  &.hasMobile {
    padding-bottom: 50px;
  }

  .screenshotFrame {
    border: 1px solid $gray;
    border-radius: 0.5rem;
    background-color: $white;
    overflow: hidden;
    display: none;
    opacity: 0;
    transition: opacity 0.15s ease-in;

    img {
      display: block;
      opacity: 0;
      transition: opacity 0.15s ease-in 0.3s;
    }

    &.desktop {
      width: 100%;
    }

    &.mobile {
      position: absolute;
      bottom: 0;
      right: 50px;

      &.mobile {
        transition-delay: 0.3s;

        img {
          transition-delay: 0.45s;
        }
      }
    }

    &.shown {
      display: block;
      opacity: 1;

      img {
        opacity: 1;
      }
    }
  }
}
