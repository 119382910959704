@import "../../color";

.navbar {
  background-color: $dark-primary;
  padding: 15px 5%;
  box-shadow: 0 6px 4px rgba(26, 13, 71, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.navList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.navItem {
  margin-top: 5px;
  position: relative;
}

.navItem::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.navItem.active::after {
  background-color: $light-blue;
}

.navItemBrand {
  color: $white;
  font-family: "Krona One", sans-serif;
  font-size: 22px;
  letter-spacing: 2px;
}

.navItem a {
  color: $white;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.navItem a:hover {
  color: $light-primary;
}
.navCenterGroup {
  display: flex;
  align-items: center;
  margin: 0;
  gap: 2rem;
}

.navRightGroup {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.navItemIcon {
  margin-top: 6px;
}

.navItemIcon a {
  color: $white;
  font-size: 26px;
}

.navItemButton {
  margin-left: 20px;
}

.navItemButton button {
  background-color: $white;
  color: $dark-primary;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  font-weight: bold;
}

.navItemDropdown {
  position: relative;
}

.dropdown {
  margin-left: 15px;
  cursor: pointer;
  margin-top: 10px;
}

.dropbtn {
  background-color: transparent;
  border: none;
  color: $white;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.dropdownContent {
  display: none;
  flex-direction: column;
  min-width: 120px;
  padding: 8px 0;
}

.dropdownContent a {
  color: $white;
  text-decoration: none;
  padding: 8px 16px;
  font-size: 20px;
}

.dropdownContent a:hover {
  background-color: $light-primary;
}

.navItemDropdown:hover .dropdownContent {
  display: flex;
  position: absolute;
  background-color: $dark-primary;
}

@media (max-width: 768px) {
  .navbar {
    padding: 15px 10px;
  }

  .navItem {
    margin-right: 10px;
    margin-top: 10px;
  }

  .navItemBrand {
    font-size: 20px;
  }

  .navCenterGroup {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }

  .navCenterGroup .navItem {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .navRightGroup {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .navItem a {
    font-size: 18px;
  }

  .navItemButton button {
    padding: 6px 8px;
    font-size: 16px;
  }

  .navList {
    flex-direction: column;
    align-items: flex-start;
  }

  .navItemIcon,
  .navItemButton,
  .navItemDropdown {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .navItemIcon a,
  .dropbtn {
    margin-left: auto;
  }
}
