@import "../../../color.scss";

.baseInput {
  &:not(:only-of-type) {
    margin-bottom: 1rem;
  }

  :global {
    .label {
      color: $black;
      display: block;
      margin-bottom: 0.5rem;
    }

    .error {
      color: $red;
      max-height: 0rem;
      height: 100%;
      overflow: hidden;
      display: block;
      opacity: 0;
      transition: max-height 0.15s ease-out 0.15s, opacity 0.15s ease-in 0s;

      &.active {
        max-height: 1rem;
        opacity: 1;
        transition: max-height 0.15s ease-in 0s, opacity 0.15s ease-in 0.15s;
      }
    }
  }
}
