@import "../../../color.scss";

.button {
  padding: 0.85rem 1rem;
  color: $white;
  cursor: pointer;
  border: 1px solid $gray;
  border-radius: 0.25rem;
  background-color: $white;
  color: $black;
  transition: all 0.15s ease-in;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 0.25rem;

  &:disabled {
    background-color: $gray;
    border-color: $gray;
    cursor: not-allowed;

    &:hover {
      border-color: $gray;
      color: $black;
    }
  }

  &:hover {
    border-color: $primary;
    color: $primary;
  }

  &:active {
    border-color: $black;
    color: $black;
    transition: none;
  }

  &.block {
    width: 100%;
  }

  &.primary {
    border-color: transparent;
    color: $white;
    background-color: $dark-primary;

    &:hover {
      background-color: $light-primary;
    }

    &:active {
      background-color: $black;
      transition: none;
    }
  }

  &.danger {
    border-color: $red;
    color: $red;
    background-color: $white;

    &:hover {
      background-color: $light-red;
      color: $white;
    }

    &:active {
      background-color: $dark-red;
      border-color: $dark-red;
      transition: none;
    }
  }

  &.google {
    border-color: $gray;
    background-color: $white;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 20px;
      width: 36px;
      height: 36px;
    }

    &:hover {
      border-color: $light-primary;
    }

    &:active {
      background-color: $white;
    }
  }

  &.stop {
    border-color: transparent;
    color: $white;
    background-color: $dark-red;
    width: 250px;
    height: 50px;

    &:hover {
      border-color: $dark-red;
    }

    &:active {
      background-color: $dark-red;
    }
  }

  &.link {
    color: $dark-blue;
    border: none;
    justify-content: flex-start;
    padding: 0.25rem 0;

    &:hover {
      color: $light-blue;
    }
  }

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  &.ghost {
    border-color: transparent;
    background-color: transparent;
  }
}
