.mobileBlocker {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  z-index: 9000;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  div {
    &:first-child {
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .mobileBlocker {
    display: none;
  }
}
