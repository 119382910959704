@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Regular.eot");
  src: url("../assets/fonts/Inter-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Inter-Regular.woff2") format("woff2"),
    url("../assets/fonts/Inter-Regular.woff") format("woff"),
    url("../assets/fonts/Inter-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Black.eot");
  src: url("../assets/fonts/Inter-Black.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Inter-Black.woff2") format("woff2"),
    url("../assets/fonts/Inter-Black.woff") format("woff"),
    url("../assets/fonts/Inter-Black.ttf") format("ttf");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Bold.eot");
  src: url("../assets/fonts/Inter-Bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Inter-Bold.woff2") format("woff2"),
    url("../assets/fonts/Inter-Bold.woff") format("woff"),
    url("../assets/fonts/Inter-Bold.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-ExtraBold.eot");
  src: url("../assets/fonts/Inter-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Inter-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/Inter-ExtraBold.woff") format("woff"),
    url("../assets/fonts/Inter-ExtraBold.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-ExtraLight.eot");
  src: url("../assets/fonts/Inter-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Inter-ExtraLight.woff2") format("woff2"),
    url("../assets/fonts/Inter-ExtraLight.woff") format("woff"),
    url("../assets/fonts/Inter-ExtraLight.ttf") format("ttf");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Light.eot");
  src: url("../assets/fonts/Inter-Light.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Inter-Light.woff2") format("woff2"),
    url("../assets/fonts/Inter-Light.woff") format("woff"),
    url("../assets/fonts/Inter-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Medium.eot");
  src: url("../assets/fonts/Inter-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Inter-Medium.woff2") format("woff2"),
    url("../assets/fonts/Inter-Medium.woff") format("woff"),
    url("../assets/fonts/Inter-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-SemiBold.eot");
  src: url("../assets/fonts/Inter-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Inter-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Inter-SemiBold.woff") format("woff"),
    url("../assets/fonts/Inter-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Regular.eot");
  src: url("../assets/fonts/Inter-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Inter-Regular.woff2") format("woff2"),
    url("../assets/fonts/Inter-Regular.woff") format("woff"),
    url("../assets/fonts/Inter-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Thin.eot");
  src: url("../assets/fonts/Inter-Thin.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Inter-Thin.woff2") format("woff2"),
    url("../assets/fonts/Inter-Thin.woff") format("woff"),
    url("../assets/fonts/Inter-Thin.ttf") format("ttf");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
