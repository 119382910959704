@import "../../../color.scss";

.select {
 
  &:not(:only-of-type) {
    margin-bottom: 1rem;
  

  }

  .label {
    color: $black;
    display: block;
    margin-bottom: 1rem;
  }

  .error {
    color: $red;
    max-height: 1rem;
    height: 100%;
    overflow: hidden;
    display: block;
    opacity: 0;

    &.active {
      max-height: 1rem;
      opacity: 1;
      transition: max-height 0.15s ease-in 0s, opacity 0.15s ease-in 0.15s;
    }
  }
}


